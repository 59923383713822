<template>
  <div>
    <div class="card">
      <div class="card-header">
        <button
          @click="$router.push('/services/add')"
          class="btn btn-lg btn-relief-success"
        >
          <i class="fa fa-plus"></i>
          إضافة خدمة جديدة
        </button>
      </div>
      <div class="card-body">
        <div class="col-12 table-responsive">
          <table
            class="table table-hover table-bordered custom-table table-striped"
          >
            <thead>
              <th>الخدمة</th>
            </thead>
            <tbody>
              <tr v-for="service in services" :key="service._id">
                <td>
                  <img
                    :src="service.image"
                    style="
                      width: 26px;
                      height: 26px;
                      object-fit: cover;
                      border-radius: 50%;
                    "
                  />
                  &nbsp;
                  {{ service.title }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      services: [],
    };
  },
  methods: {},
  created() {
    var g = this;
    $.post(api + "/console/services/index", {
      jwt: this.user.jwt,
    }).then(function (r) {
      g.services = JSON.parse(r).response;
    });
  },
};
</script>